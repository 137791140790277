import { Component, Input, OnInit } from '@angular/core';
import {
  // NbMenuService, NbSidebarService,
   NbDateService } from '@nebular/theme';
// import { UserData } from '../../../@core/data/users';
// import { AnalyticsService } from '../../../@core/utils';
// import { LayoutService } from '../../../@core/utils';
import { Observable } from 'rxjs';
import { PresService } from '../../../app.service';
import {
  // ActivatedRoute,
   Router
  // ,NavigationEnd
} from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';
  datestream: Observable<any>;
  date = new Date();
  user: any;
  pid: any;
  profileDetails: any;
  menu = true;
test_var =10;
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(
    //private sidebarService: NbSidebarService,
             // private menuService: NbMenuService,
             // private userService: UserData,
              // private analyticsService: AnalyticsService,
              // private layoutService: LayoutService,
              protected dateService: NbDateService<Date>,
              public presService: PresService,
              // private route:ActivatedRoute,
              private router:Router,
              ) {
                this.pid = localStorage.getItem("pid");
                if(this.pid){
                  this.presService.hideheadernavs = false;
                }else{
                  this.presService.hideheadernavs = true;
                }
                  

  }

  ngOnInit() {
    
    this.presService.getPharmacy_profile({"pharmacy_id":this.pid}).subscribe((response)=> {
      console.log(response['profile_details'][0]);
      this.profileDetails = response['profile_details'][0];
      console.log(this.profileDetails);
    });

    // console.log(this.router.url);
    // if(this.router.url){
    //   this.menu = false;
    // }else{
    //   this.menu = true;
    // }
    /*  === '/login' */

    // this.userService.getUsers()
     // .subscribe((users: any) => this.user = users.nick);

      /* if(this.pid){
      } */
  }

  toggleSidebar(): boolean {
   // this.sidebarService.toggle(true, 'menu-sidebar');
   // this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    // this.menuService.navigateHome();
  }
  logout() {
    localStorage.setItem("pid", "");
    this.pid = "";
    this.router.navigate(['/ursugarmeds/auth/login']);
  }

  startSearch() {
    // this.analyticsService.trackEvent('startSearch');
  }



  // Three places of updating dates.
  // Click prev day button.
  // Click next day button.
  // Select calendar and choose a desired date.
  handleNextDay() {
    this.date = this.dateService.addDay(this.date, 1);
    console.log(this.date);
    this.presService.updateDate(this.date);
  }
  handlePrevDay() {
    this.presService.updateDate(this.date);
    this.date = this.dateService.addDay(this.date, -1);
    console.log(this.date);
  }

  handleDateChange(event) {
    // console.log(data);
    // console.log(this.test_var);
    console.log(this.date);
    console.log(event);
    this.presService.updateDate(this.date);
    // this.date = event;
  }
}
